import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Typography, Grid, Paper } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export default function PriceTierForm() {
    const [priceTiers, setPriceTiers] = useState([{ maxWeight: '', price: '' }]);
    const [deliveryEstimation, setDeliveryEstimation] = useState({ minDays: '', maxDays: '' });
    const [pickupFee, setPickupFee] = useState('');

    const handlePriceTierChange = (index, event) => {
        const newPriceTiers = [...priceTiers];
        newPriceTiers[index][event.target.name] = event.target.value;
        setPriceTiers(newPriceTiers);
    };

    const handleDeliveryEstimationChange = (event) => {
        setDeliveryEstimation({
            ...deliveryEstimation,
            [event.target.name]: event.target.value
        });
    };

    const handlePickupFeeChange = (event) => {
        setPickupFee(event.target.value);
    };

    const addPriceTier = () => {
        setPriceTiers([...priceTiers, { maxWeight: '', price: '' }]);
    };

    const removePriceTier = index => {
        const newPriceTiers = [...priceTiers];
        newPriceTiers.splice(index, 1);
        setPriceTiers(newPriceTiers);
    };

    const fetchCurrentRates = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/order/quote`);
            const data = await response.json();
            setPriceTiers(data.packagesRaw.map(d => ({
                maxWeight: "" + d.maxWeight,
                price: "" + d.price
            })));
            setDeliveryEstimation({
                minDays: data.minDelivery,
                maxDays: data.maxDelivery
            });
            // Fetch pickup fee
            const pickupFeeResponse = await fetch(`${process.env.REACT_APP_API_URL}/admin/pickup-fee`);
            const pickupFeeData = await pickupFeeResponse.json();
            setPickupFee(pickupFeeData.price.toString());
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchCurrentRates();
    }, []);

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/price`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newPriceTiers: priceTiers.map(tier => ({
                        maxWeight: parseFloat(tier.maxWeight),
                        price: parseFloat(tier.price),
                    })),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            return MySwal.fire('Price tiers updated successfully');
        } catch (error) {
            console.error('Failed to update price tiers', error);
            MySwal.fire('Failed to update price tiers', '', 'error');
        }
    };

    const handleDeliveryEstimationSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/delivery-estimation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    minDays: parseInt(deliveryEstimation.minDays),
                    maxDays: parseInt(deliveryEstimation.maxDays),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            return MySwal.fire('Delivery estimation updated successfully');
        } catch (error) {
            console.error('Failed to update delivery estimation', error);
            MySwal.fire('Failed to update delivery estimation', '', 'error');
        }
    };

    const handlePickupFeeSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/pickup-fee`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    price: parseFloat(pickupFee),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            return MySwal.fire('Pickup fee updated successfully');
        } catch (error) {
            console.error('Failed to update pickup fee', error);
            MySwal.fire('Failed to update pickup fee', '', 'error');
        }
    };

    return (
        <Container>
            <Typography variant="h2" sx={{ mt: '10vh', mb: 4 }}>
                Admin Panel
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h4" sx={{ mb: 3 }}>
                            Add/Update Tariff Rates
                        </Typography>
                        <Box sx={{
                            '& > :not(style)': { my: 3 },
                            '& > :first-of-type': { mt: 0 }
                        }}>
                            {priceTiers.map((tier, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <TextField
                                        label="Max Weight"
                                        variant="outlined"
                                        name="maxWeight"
                                        type="number"
                                        value={tier.maxWeight}
                                        onChange={e => handlePriceTierChange(index, e)}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Price"
                                        variant="outlined"
                                        name="price"
                                        type="number"
                                        value={tier.price}
                                        onChange={e => handlePriceTierChange(index, e)}
                                        fullWidth
                                    />
                                    <Button onClick={() => removePriceTier(index)} sx={{ color: '#ec1c24' }}>
                                        Remove
                                    </Button>
                                </Box>
                            ))}
                            <Button onClick={addPriceTier}>Add Another Tier</Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                                Submit Price Tiers
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                        <Typography variant="h4" sx={{ mb: 3 }}>
                            Update Delivery Estimation
                        </Typography>
                        <Box sx={{
                            '& > :not(style)': { my: 3 },
                            '& > :first-of-type': { mt: 0 }
                        }}>
                            <TextField
                                label="Minimum Days"
                                variant="outlined"
                                name="minDays"
                                type="number"
                                value={deliveryEstimation.minDays}
                                onChange={handleDeliveryEstimationChange}
                                fullWidth
                            />
                            <TextField
                                label="Maximum Days"
                                variant="outlined"
                                name="maxDays"
                                type="number"
                                value={deliveryEstimation.maxDays}
                                onChange={handleDeliveryEstimationChange}
                                fullWidth
                            />
                            <Button variant="contained" color="primary" onClick={handleDeliveryEstimationSubmit} fullWidth>
                                Update Delivery Estimation
                            </Button>
                        </Box>
                    </Paper>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h4" sx={{ mb: 3 }}>
                            Update Pickup Fee
                        </Typography>
                        <Box sx={{
                            '& > :not(style)': { my: 3 },
                            '& > :first-of-type': { mt: 0 }
                        }}>
                            <TextField
                                label="Pickup Fee"
                                variant="outlined"
                                type="number"
                                value={pickupFee}
                                onChange={handlePickupFeeChange}
                                fullWidth
                            />
                            <Button variant="contained" color="primary" onClick={handlePickupFeeSubmit} fullWidth>
                                Update Pickup Fee
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}