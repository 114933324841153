import React, { useState } from 'react';
import { Button, useTheme, useMediaQuery } from '@mui/material';
import ConfirmationModal from '../components/sharable/ConfirmationModal';
import { initCustomerService } from '../services/customerService';

const DeleteAccountButton = ({ btnText, url, onActionSuccess }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const customerService = initCustomerService();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const data = await customerService.deleteOrDeactivateAccount(url);
      console.log('Account deleted/deactivated successfully:', data);
      alert('Account deleted/deactivated successfully');
      onActionSuccess();
    } catch (error) {
      console.error('Error deleting/deactivating Account:', error);
      alert(`Failed to delete/deactivate Account: ${error.message}`);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant="contained"
        sx={{
          my: 1,
          backgroundColor: "#ec1c24",
          border: '1px solid',
          borderRadius: "10px",
          color: 'white',
          textTransform: 'none',
          fontSize: "1rem",
          width: isMobileOrTablet ? "20%" : "35%",
          height: '5vh',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          paddingX: '32px',
          '&:hover': {
            backgroundColor: "#ec1c24",
            boxShadow: '0 0 5px #ec1c24',
          },
        }}
      >
        {btnText}
      </Button>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title={`Confirm ${btnText}`}
        message={`Are you sure you want to ${btnText.toLowerCase()} this account? This action cannot be undone.`}
        confirmText={btnText}
      />
    </>
  );
};

export default DeleteAccountButton;