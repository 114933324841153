import React from 'react';
import { Typography } from '@mui/material';

const InfoField = ({ variant, children }) => {
    return (
        <Typography 
            sx={{ 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap' 
            }} 
            variant={variant}
        >
            {children}
        </Typography>
    );
};

export default InfoField;
