import React, { useState } from 'react';
import { Paper, Button, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUser } from '../state';
import InfoField from '../scenes/customerDashboard/InfoField';
import ConfirmationModal from './sharable/ConfirmationModal';
import { initCustomerService } from '../services/customerService';

const AddressCard = ({ address, id }) => {
  const dispatch = useDispatch();
  const [openedModal, setOpenedModal] = useState(false);
  const customerService = initCustomerService();

  const handleOpenModal = () => setOpenedModal(true);
  const handleCloseModal = () => setOpenedModal(false);

  const handleDelete = async () => {
    try {
      const data = await customerService.removeAddress(id, address._id);
      console.log('Address deleted successfully:', data);
      dispatch(setUser({ user: data }));
      alert('Address deleted successfully');
    } catch (error) {
      console.error('Error deleting address:', error);
      alert(`Failed to delete address: ${error.message}`);
    }
    handleCloseModal();
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          border: '1px solid #ccc',
          borderRadius: '8px',
          minWidth: "200px",
          maxWidth: "300px",
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: '10px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <InfoField variant="body1"><strong>{address.fullName}</strong></InfoField>
          <InfoField variant="body2">{address.address1}</InfoField>
          <InfoField variant="body2">{address.city}</InfoField>
          <InfoField variant="body2">{address.postCode}</InfoField>
          <InfoField variant="body2">{address.country}</InfoField>
        </Box>

        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{
            my: 1,
            backgroundColor: "#ec1c24",
            border: '1px solid',
            borderRadius: "10px",
            color: 'white',
            textTransform: 'none',
            fontSize: "1rem",
            width: '100%',
            height: '56px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            paddingX: '32px',
            '&:hover': {
              backgroundColor: "#ec1c24",
              boxShadow: '0 0 5px #ec1c24',
            },
          }}
        >
          Delete
        </Button>
      </Paper>

      <ConfirmationModal
        open={openedModal}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this address? This action cannot be undone."
      />
    </>
  );
};

export default AddressCard;
