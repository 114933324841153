import React from 'react';
import {
  TextField, Button,
  Box, Typography,
  useMediaQuery, useTheme,
  InputAdornment, FormControl,
} from '@mui/material';
import { AccountCircle, Email } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../state';
import { MuiTelInput } from 'mui-tel-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validationRule } from '../../constants/validationRule';
import { initCustomerService } from '../../services/customerService';

const PersonalInfo = () => {
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const customerService = initCustomerService();

  const validationSchema = Yup.object().shape({
    fullName: validationRule.FULLNAME,
    phone: validationRule.PHONE,
    email: validationRule.EMAIL,
  });

  const formik = useFormik({
    initialValues: {
      fullName: user.fullName || "",
      phone: user.phone || "",
      email: user.email || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const data = await customerService.updateCustomerInfo(user._id, values);
        console.log('User info updated:', data);
        dispatch(setUser({ user: data }));
        alert('Changes saved successfully!');
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while saving changes.');
      }
    },
  });

  return (
    <Box width="100%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant='h2' sx={{ fontWeight: 600, color: '#2f3135', textAlign: "center" }} gutterBottom>
        Personal Info
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }} gutterBottom>
        Review and update your personal details here
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: '400' }}>
            Your Full Name
          </Typography>
          <TextField
            fullWidth
            id="fullName"
            name="fullName"
            variant="outlined"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
            sx={{
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                borderRadius: "5px",
                boxShadow: '0 0 5px #ec1c24',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="h5" sx={{ fontWeight: '400' }}>
            Your Phone number
          </Typography>

          <FormControl
            fullWidth
            sx={{
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                boxShadow: '0 0 5px #ec1c24',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
          >
            <MuiTelInput
              id="phone"
              name="phone"
              defaultCountry={'GB'}
              value={formik.values.phone}
              onChange={(value) => formik.setFieldValue('phone', value)}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              fullWidth
              placeholder="Phone Number"
              variant="outlined"
              sx={{
                transition: 'box-shadow 0.3s ease-in-out',
                width: '100%',
                '& .MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
              }}
            />
          </FormControl>

          <Typography variant="h5" sx={{ fontWeight: '400' }}>
            Your E-mail Address
          </Typography>
          <TextField
            fullWidth
            id="email"
            name="email"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                borderRadius: "5px",
                boxShadow: '0 0 5px #ec1c24',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "white",
              border: '1px solid',
              borderRadius: "10px",
              color: ' #ec1c24',
              textTransform: 'none',
              fontSize: '1.25rem',
              width: isMobileOrTablet ? "100%" : "330px",
              height: '56px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              paddingX: '32px',
              '&:hover': {
                backgroundColor: "white",
                boxShadow: '0 0 5px #ec1c24',
              },
            }}
          >
            Save Changes
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default PersonalInfo;