import { useState, useEffect, useMemo } from 'react';
import { initCustomerService } from '../services/customerService';

export const useCustomerOrders = (customerId) => {
    const [orders, setOrders] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const customerService = useMemo(() => initCustomerService(), []);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const fetchedOrders = await customerService.getCustomerOrders(customerId);
                setOrders(fetchedOrders);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch orders:', error);
                setError(error.message);
                setLoading(false);
            }
        };

        fetchOrders();
    }, [customerId, customerService]);

    return { orders, loading, error };
};
