import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { initCustomerService } from '../../services/customerService';

export const EmailVerificationPage = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const customerService = initCustomerService();

    useEffect(() => {
        const verifyEmail = async () => {
            if (token) {
                try {
                    const data = await customerService.validateEmail(token);
                    setIsTokenValid(data.isTokenValid);
                } catch (error) {
                    setErrorMessage(error.response?.data?.message || 'An error occurred during email verification.');
                } finally {
                    setLoading(false);
                }
            }
        };

        verifyEmail();
    }, [token]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'top',
                pt: '12%',
                minHeight: '100vh',
            }}
        >
            {loading ? (
                <CircularProgress sx={{ mt: '2.5em' }} color={'error'} />
            ) : (
                <Card
                    variant="outlined"
                    sx={{
                        padding: 3,
                        maxWidth: 400,
                        height: 'fit-content',
                        textAlign: 'center',
                        borderColor: '#ec1c24',
                        borderWidth: 2,
                        backgroundColor: 'white',
                    }}
                >
                    {isTokenValid ? (
                        <Typography variant="h5" gutterBottom>
                            Your email has been successfully verified. Please proceed to login.
                        </Typography>
                    ) : (
                        errorMessage && (
                            <Typography variant="h5" color={'error'} gutterBottom>
                                {errorMessage}
                            </Typography>
                        )
                    )}
                    <Button
                        onClick={() => navigate('/customer')}
                        variant={'contained'}
                        sx={{
                            backgroundColor: '#ec1c24',
                            color: 'white',
                            '&:hover': { backgroundColor: '#b7181f' },
                            mt: 2,
                            mb: 1,
                        }}
                        fullWidth
                    >
                        Proceed to login
                    </Button>
                </Card>
            )}
        </Box>
    );
};