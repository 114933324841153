import { getAxiosInstance } from './axiosService';

export function initUserService() {
    const { axios } = getAxiosInstance();
    const basePath = '/auth';

    async function registerCollaborator(payload) {
        try {
            const { data } = await axios.post(`${basePath}/register`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function fetchCollaborators() {
        try {
            const { data } = await axios.get(`${basePath}/getAll`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function fetchConsents() {
        try {
            const { data } = await axios.get(`${basePath}/show-consent`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function recordConsent(consentGiven = true) {
        try {
            const consentInfo = {
                consentGiven,
                timestamp: new Date().toISOString(),
            };
            const { data } = await axios.post(`${basePath}/record-consent`, consentInfo);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function login(email, password) {
        const { data } = await axios.post(`${basePath}/login`, { email, password });
        return data;
    }

    async function resetPassword(email) {
        const { data } = await axios.post(`${basePath}/forgetPassword`, { email });
        return data;
    }

    async function resetPasswordWithToken(token, newPassword) {
        const { data } = await axios.post(`${basePath}/resetPassword`, { token, newPassword });
        return data;
    }

    return {
        registerCollaborator,
        fetchCollaborators,
        fetchConsents,
        recordConsent,
        login,
        resetPassword,
        resetPasswordWithToken
    };
}