import React from 'react';
import { Typography } from '@mui/material';
import OrderDetailsCard from '../../components/OrderDetailCard';

const OrdersDisplay = ({ orders }) => {
    if (!orders || orders.length === 0) {
        return (
            <div>
                <Typography variant="h6" gutterBottom>
                    Current Orders
                </Typography>
                <Typography variant="body1">
                    You have no active orders
                </Typography>
            </div>
        );
    }

    return (
        <div>
            {orders.map((order) => (
                <OrderDetailsCard key={order.id} orderDetails={order} />
            ))}
        </div>
    );
};

export default OrdersDisplay;