import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import AddressCard from '../../components/AddressBookCard';
import { useSelector } from "react-redux";

const AddressBook = () => {
    const user = useSelector((state) => state.auth.user);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ flexGrow: 1, textAlign: 'center', mb: 5 }}>
                <Typography variant='h2' sx={{ fontWeight: 600, color: '#2f3135', textAlign: "center" }} gutterBottom>
                    Address Book
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    A list of your previously used addresses. You can delete any that you do not think you will use again.
                </Typography>
            </Box>

            <Grid container justifyContent="center" spacing={4} style={{ gap: '20px' }}>
                {user.addressBook.map((address) => (
                    <Grid item key={address._id} xs={12} sm={6} md={4} lg={3}>
                        <AddressCard address={address} id={user._id} />
                    </Grid>
                ))}
            </Grid>

            {user.addressBook.length === 0 && (
                <Typography variant="body1" gutterBottom>
                    You have no previous addresses.
                </Typography>
            )}
        </Box>
    );
}

export default AddressBook;


