import React from 'react';
import { Box, Typography, ButtonBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ButtonData = [
  {
    label: 'Gifts For Every Moment',
    url: 'https://sentimentsexpress.com/',
    imgSrc: '/assets/Sentiments1.png',
  },
  {
    label: 'Fashion Delivered Worldwide',
    url: 'https://www.studiobytcs.com/',
    imgSrc: '/assets/StudioBlack1.png',
  },
];

const BiggerButton = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Instantiate the navigate function
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const handleButtonClick = url => {
    window.location.href = url;
    // Alternatively, to open in a new tab, you can use:
    // window.open(url, '_blank');
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: 'md',
          display: 'flex',
          flexDirection: isMobileOrTablet ? 'column' : 'row',
          justifyContent: 'space-between',
          padding: 2,
        }}
      >
        <ButtonBase
          onClick={() => navigate('/luggage-plus')} // Use navigate with button's navigation property
          sx={{
            width: isMobileOrTablet ? '90vw' : 400,
            border: 1,
            borderColor: '#808080',
            borderRadius: '2px',
            overflow: 'hidden',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 0 5px #ec1c24',
              border: 'none',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              height: isMobileOrTablet ? 280 : 350,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${'/assets/courier-no-text.jpeg'})`,
                backgroundPosition: 'center',
                backgroundSize: 'Cover',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                filter: 'brightness(80%)',
              }}
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                boxShadow: 'inset 0px -20px 40px 9px #00000057',
                zIndex: 9,
              }}
            />
          </Box>
          <Box
            sx={{
              p: 2,
              textAlign: 'center',
              position: 'absolute',
              bottom: 15,
              backgroundColor: '#ec1c24',
              borderRadius: 1000,
              zIndex: 10,
              width: 205,
              boxShadow: '1px 4px 11px 0px #c6b1b7',
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{
                fontSize: 16,
                marginBottom: 0,
                color: 'white',
              }}
            >
              {'TCS Express Boxes'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {'Ship Ahead, Travel Light!'}
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
      <Box
        sx={{
          maxWidth: 'md',
          display: 'flex',
          flexDirection: isMobileOrTablet ? 'column' : 'row',
          justifyContent: 'space-between',
          padding: 2,
          gap: isMobileOrTablet ? 2 : 5,
          marginTop: isMobileOrTablet ? '5vh' : '9vh',
        }}
      >
        {ButtonData.map((button, index) => (
          <ButtonBase
            key={index}
            onClick={() => handleButtonClick(button.url)}
            sx={{
              width: isMobileOrTablet ? '90vw' : 400,
              border: 1,
              borderColor: '#808080',
              borderRadius: '2px',
              overflow: 'hidden',
              marginBottom: isMobileOrTablet ? '2vh' : '10vh',
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                boxShadow: '0 0 5px #ec1c24',
                border: 'none',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: isMobileOrTablet ? 280 : 350,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${button.imgSrc})`,
                  backgroundSize: 'Cover',
                  backgroundPosition: 'top',
                  backgroundRepeat: 'no-repeat',
                  position: 'absolute',
                  filter: 'brightness(80%)',
                }}
              />
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  boxShadow: 'inset 0px -20px 40px 29px #00000057',
                  zIndex: 9,
                }}
              />
              <Box
                sx={{
                  p: 2,
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 15,
                  backgroundColor: '#ec1c24',
                  borderRadius: 1000,
                  zIndex: 10,
                  boxShadow: '1px 4px 11px 0px #c6b1b7',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    fontSize: 16,
                    marginBottom: 0,
                    color: 'white',
                  }}
                >
                  {button.label}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {button.subLabel}
                </Typography>
              </Box>
            </Box>
          </ButtonBase>
        ))}
      </Box>
    </>
  );
};

export default BiggerButton;
