import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import OrdersDisplay from './OrdersDisplay';
import { useCustomerOrders } from '../../hooks/useCustomerOrders';

const CustomerDashboard = () => {
    const customerId = useSelector(state => state.auth.user._id);
    const { orders, loading, error } = useCustomerOrders(customerId);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ flexGrow: 1, textAlign: 'center', mb: 5 }}>
                <Typography variant="h2" sx={{ fontWeight: 600, color: '#2f3135', textAlign: 'center' }} gutterBottom>
                    Dashboard
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Welcome back to your account, here you can find all your current and previous orders, view tracking
                    information and print the important documents to go with your orders.
                </Typography>
            </Box>
            {loading && <Typography>Loading orders...</Typography>}
            {error && <Typography color="error">Error loading orders: {error}</Typography>}
            {orders && <OrdersDisplay orders={orders} />}
        </Box>
    );
};

export default CustomerDashboard;