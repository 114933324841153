import LoginCustomer from '../scenes/loginpageCustomer/LoginCustomer';
import { getAxiosInstance } from './axiosService';

export function initCustomerService() {
    const { axios } = getAxiosInstance();
    const basePath = '/customer';

    async function removeAddress(customerId, addressId) {
        try {
            const { data } = await axios.get(`${basePath}/removeAddress/${customerId}/${addressId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function deleteOrDeactivateAccount(url) {
        try {
            const { data } = await axios.get(`${basePath}/${url}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function getCustomerOrders(customerId) {
        try {
            const { data } = await axios.get(`/order/customer/${customerId}`);
            return data.orders;
        } catch (error) {
            throw error;
        }
    }

    async function getAllCustomers() {
        try {
            const { data } = await axios.get(`${basePath}/getAll`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function loginCustomer(payload) {
        try {
            const { data } = await axios.post(`${basePath}/login`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function registerCustomer(payload) {
        try {
            const { data } = await axios.post(`${basePath}/register`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function resetCustomerPassword(payload) {
        try {
            const { data } = await axios.post(`${basePath}/reset`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function updateCustomerInfo(customerId, userData) {
        try {
            const { data } = await axios.post(`${basePath}/edit/${customerId}`, userData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function validateEmail(token) {
        try {
            const { data } = await axios.post(`${basePath}/validate-email`, { token });
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function confirmNewPassword(payload) {
        try {
            const { data } = await axios.post(`${basePath}/resetPassword`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    return {
        removeAddress,
        deleteOrDeactivateAccount,
        getCustomerOrders,
        getAllCustomers,
        updateCustomerInfo,
        validateEmail,
        loginCustomer,
        registerCustomer,
        resetCustomerPassword,
        confirmNewPassword
    };
}