import { getAxiosInstance } from './axiosService';

export function initInquiryService() {
    const { axios } = getAxiosInstance();
    const basePath = '/inquiry';

    async function respondToInquiry(inquiryId, responseMessage) {
        try {
            const { data } = await axios.post(`${basePath}/respond`, {
                inquiryId,
                responseMessage
            });
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function getAllInquiries() {
        try {
            const { data } = await axios.get(`${basePath}/getAll`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async function createNewInquiry(inquiryData) {
        try {
            const { data } = await axios.post(`${basePath}/new`, inquiryData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    return {
        respondToInquiry,
        getAllInquiries,
        createNewInquiry
    };
}