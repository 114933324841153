import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Card, Box
} from '@mui/material';
import { initUserService } from '../../services/userService';

const ConsentTable = () => {
  const [consents, setConsents] = useState([]);
  const [error, setError] = useState(null);

  const userService = useMemo(() => initUserService(), []);

  const fetchConsents = useCallback(async () => {
    try {
      const data = await userService.fetchConsents();
      setConsents(data);
    } catch (error) {
      console.error("Error fetching consents:", error);
      setError("Failed to fetch consents. Please try again later.");
    }
  }, [userService]);

  useEffect(() => {
    fetchConsents();
  }, [fetchConsents]);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant='h3' sx={{ fontWeight: 500, position: "sticky", mt: 2, display: "flex", color: "#528aae" }}>
        GDPR Consent Log
      </Typography>

      <TableContainer component={Card} sx={{ mt: "6vh", minWidth: "70vw" }}>
        <Table aria-label="consent table">
          <TableHead>
            <TableRow>
              <TableCell>Consent</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consents.map((consent) => (
              <TableRow key={consent._id}>
                <TableCell>{consent.consentGiven ? 'Yes' : 'No'}</TableCell>
                <TableCell>{consent.timestamp}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ConsentTable;