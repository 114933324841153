import { Paper, Typography, Button, Box, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../state';
import { initCustomerService } from '../services/customerService';

const DeleteAccountCard = ({ title, btnText, url }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerService = initCustomerService();

  const handleDelete = async () => {
    try {
      const data = await customerService.deleteOrDeactivateAccount(url);
      console.log('Account deleted/deactivated successfully:', data);
      alert('Account deleted/deactivated successfully');
      handleLogout();
    } catch (error) {
      console.error('Error deleting Account:', error);
      alert(`Failed to delete Account: ${error.message}`);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/customer");
  };

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        border: '1px solid #ccc',
        borderRadius: '4px',
        minWidth: "200px",
        width: isMobileOrTablet ? '90%' : '50%',
        mb: 2,
        mx: 1,
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0.5
      }}>
        <Typography variant="body1"><strong>{title}</strong></Typography>
        <Button
          onClick={handleDelete}
          variant="contained"
          sx={{
            my: 1,
            backgroundColor: "#ec1c24",
            border: '1px solid',
            borderRadius: "10px",
            color: 'white',
            textTransform: 'none',
            fontSize: "1rem",
            width: isMobileOrTablet ? "100%" : "13vw",
            height: '56px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            paddingX: '32px',
            '&:hover': {
              backgroundColor: "#ec1c24",
              boxShadow: '0 0 5px #ec1c24',
            },
          }}
        >
          {btnText}
        </Button>
      </Box>
    </Paper>
  );
};

export default DeleteAccountCard;