import React from 'react';
import { Box, Button, Typography, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const OrderDetailsCard = ({ orderDetails }) => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const handleDownloadReceipt = () => {
        window.open(`${process.env.REACT_APP_API_URL}/services/getInvoice/Payment_Invoice_${orderDetails.id}`);
        console.log('Download receipt clicked');
    };

    const handleViewOrder = () => {
      navigate(`/tracking/${orderDetails.cn}`);

        // window.open(`${process.env.REACT_APP_API_URL}/services/getInvoice/Invoice_${orderDetails.id}`);
    };

    return (
        <Paper sx={{ p: 2, mb: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: isMobileOrTablet ? 'column' : 'row',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: 600 }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            <strong>Tracking #</strong>
                        </Typography>
                        <Typography variant="body1">{orderDetails.cn}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" minWidth="20vw">
                        <Typography variant="subtitle1">
                            <strong>Route</strong>
                        </Typography>
                        <Typography variant="body1">
                            {orderDetails.origin} to {orderDetails.destination}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            <strong>Order Status</strong>
                        </Typography>
                        <Typography variant="body1">{orderDetails.orderStatus}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            <strong>Date Booked</strong>
                        </Typography>
                        <Typography variant="body1">{new Date(orderDetails.createdAt).toLocaleDateString()}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            <strong>Collection</strong>
                        </Typography>
                        <Typography variant="body1">
                            {new Date(orderDetails.collectDateFrom).toLocaleDateString()}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 2 }}>
                    <Button
                        onClick={handleDownloadReceipt}
                        variant="contained"
                        sx={{
                            mb: 1,
                            backgroundColor: '#ec1c24',
                            border: '1px solid',
                            borderRadius: '10px',
                            color: 'white',
                            textTransform: 'none',
                            fontSize: isMobileOrTablet ? '1rem' : '1vw',
                            width: isMobileOrTablet ? '100%' : '13vw',
                            height: '56px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            paddingX: '32px',
                            '&:hover': {
                                backgroundColor: '#ec1c24',
                                boxShadow: '0 0 5px #ec1c24',
                            },
                        }}
                    >
                        Download Receipt
                    </Button>
                    <Button
                        onClick={handleViewOrder}
                        variant="contained"
                        sx={{
                            backgroundColor: orderDetails.commercialInvoicePath ? 'white' : '#f0f0f0',
                            border: '1px solid',
                            borderRadius: '10px',
                            color: orderDetails.commercialInvoicePath ? '#ec1c24' : '#a0a0a0',
                            textTransform: 'none',
                            fontSize: isMobileOrTablet ? '1rem' : '1vw',
                            width: isMobileOrTablet ? '100%' : '13vw',
                            height: '56px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            paddingX: '32px',
                            '&:hover': {
                                backgroundColor: orderDetails.commercialInvoicePath ? 'white' : '#f0f0f0',
                                boxShadow: orderDetails.commercialInvoicePath ? '0 0 5px #ec1c24' : 'none',
                            },
                        }}
                    >
                        View Order
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default OrderDetailsCard;