import { useState, useMemo } from 'react';
import { Box, Card, CardContent, TextField, Button, FormControl, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAdminLogin } from '../../state/index';
import { initUserService } from '../../services/userService';

function LoginComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isResetMode, setIsResetMode] = useState(false); // New state for toggle
    const dispatch = useDispatch();

    const userService = useMemo(() => initUserService(), []);

    const handleLogin = async e => {
        e.preventDefault();
        try {
            if (isResetMode) {
                await userService.resetPassword(email);
                setMessage('Reset Password Mail sent');
                console.log('Reset password for:', email);
                return;
            }

            const data = await userService.login(email, password);
            if (data.token) {
                dispatch(setAdminLogin({ adminLogin: true }));
            }
        } catch (error) {
            setMessage(`Login attempt failed. Check your credentials or contact administrator!`);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > :not(style)': { m: 1 },
            }}
        >
            <Card variant="outlined" sx={{ borderColor: '#ec1c24', borderWidth: 2 }}>
                <CardContent>
                    <Box
                        component="form"
                        sx={{
                            p: 3,
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleLogin}
                    >
                        <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
                            {isResetMode ? 'Reset Password' : 'Admin Login'}
                        </Typography>
                        <FormControl fullWidth sx={{ minWidth: '400px' }}>
                            <TextField
                                required
                                id="email"
                                label="Email"
                                type="email"
                                autoComplete="current-email"
                                variant="outlined"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                sx={{
                                    minWidth: '400px',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 0 5px  #ec1c24',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                            {!isResetMode && (
                                <TextField
                                    required
                                    id="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    sx={{
                                        minWidth: '400px',
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        '&:hover': {
                                            boxShadow: '0 0 5px  #ec1c24',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            )}
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    backgroundColor: 'white',
                                    border: '1px solid',
                                    borderRadius: '10px',
                                    color: ' #ec1c24',
                                    textTransform: 'none',
                                    fontSize: '1.25rem',
                                    mt: 3,
                                    height: '56px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    paddingX: '32px',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        boxShadow: '0 0 5px   #ec1c24',
                                    },
                                }}
                            >
                                {isResetMode ? 'Reset' : 'Login'}
                            </Button>
                            {/* <Button
                onClick={() => setIsResetMode(!isResetMode)}
                sx={{
                  mt: 2,
                  textDecoration: 'underline'
                }}
              >
                {isResetMode ? 'Back to login' : 'Forgot password?'}
              </Button>
              */}
                            <Typography sx={{ width: '280px', mx: 1, marginTop: 2 }} color="error">
                                {message}
                            </Typography>
                        </FormControl>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default LoginComponent;
