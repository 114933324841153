import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Card, CardContent, TextField, Button, Typography } from '@mui/material';
import Collaborators from '../../components/Collabs';
import { initUserService } from '../../services/userService';

function AddCollab() {
  const [formData, setFormData] = useState({ email: '', password: '', firstName: '', lastName: '' });
  const [message, setMessage] = useState('');
  const [collabs, setCollabs] = useState([]);

  const userService = useMemo(() => initUserService(), []);

  const fetchCollabs = useCallback(async () => {
    try {
      const data = await userService.fetchCollaborators();
      setCollabs(data);
    } catch (error) {
      console.error('Error fetching collaborators:', error);
      setMessage('Failed to fetch collaborators. Please try again.');
    }
  }, [userService]);

  useEffect(() => {
    fetchCollabs();
  }, [fetchCollabs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await userService.registerCollaborator(formData);
      setMessage("Registration Successful");
      await fetchCollabs();
      setFormData({ email: '', password: '', firstName: '', lastName: '' });
    } catch (error) {
      console.error(`Register error:`, error);
      setMessage("Registration Failed: " + (error.response?.data?.message || error.message));
    }
  };

  return (
    <>
      <Box sx={{ mt: "5vh", display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', '& > :not(style)': { m: 1 } }}>
        <Card variant="outlined" sx={{ borderColor: '#ec1c24', borderWidth: 2 }}>
          <Collaborators collabs={collabs} />
        </Card>
      </Box>
      <Box sx={{ mt: "5vh", display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', '& > :not(style)': { m: 1 } }}>
        <Card variant="outlined" sx={{ borderColor: '#ec1c24', borderWidth: 2 }}>
          <CardContent>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', p: 3, '& .MuiTextField-root': { m: 1, width: '35ch' } }} noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Typography variant='h3' sx={{ textAlign: 'center', mb: 2 }}>
                Add Collaborator
              </Typography>
              {['firstName', 'lastName', 'email', 'password'].map((field) => (
                <TextField
                  key={field}
                  id={field}
                  name={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                  type={field === 'password' ? 'password' : 'text'}
                  variant="outlined"
                  value={formData[field]}
                  onChange={handleInputChange}
                  fullWidth
                />
              ))}
              <Button variant="contained" type='submit' sx={{ backgroundColor: "#ec1c24", color: 'white', '&:hover': { backgroundColor: "#b7181f" }, mt: 2, mb: 1 }} fullWidth>
                Add Collaborator
              </Button>
              {message && <Typography color="primary">{message}</Typography>}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default AddCollab;
