import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormControl, TextField, InputAdornment, Button, Typography } from '@mui/material';
import TrackingTable from '../trackingpage/TrackingTable'; // Adjust the import path as needed
import ShipmentDetails from '../../components/ShipmentDetails';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

const steps = [
  {
    label: 'Waiting for Confirmation',
    // description: `For each ad campaign that you create, you can control how much
    //           you're willing to spend on clicks and conversions, which networks
    //           and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Pickup in Progress',
    // description: 'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'In Transit',
    // description: `Try out different ad text to see what brings in the most customers,
    //           and learn how to enhance your ads using features like ad extensions.
    //           If you run into any problems with your ads, find out how to tell if
    //           they're running and how to resolve approval issues.`,
  },
  {
    label: 'In Transit (Dubai)',
  },
  {
    label: 'Delivered',
  },
];

const TrackingInput = () => {
  const { consignmentNo: consignmentNoParam } = useParams();
  const [consignmentNo, setConsignmentNo] = useState('');
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState('');
  const [shipment, setShipment] = useState(null);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    // Initialize consignmentNo state with URL parameter if available
    if (consignmentNoParam) {
      setConsignmentNo(consignmentNoParam);
      fetchTrackingInfo(consignmentNoParam); // Automatically fetch tracking info if consignmentNo is from URL
    }
  }, [consignmentNoParam]);

  const fetchTrackingInfo = async consignmentNo => {
    try {
      // // const response = await fetch(`https://connect.tcscourier.com/tracking/api/Tracking/GetDynamicTrackDetail?consignee=${consignmentNo}`, {
      // //   method: 'GET',
      // //   headers: {
      // //     'Accept': '*/*',
      // //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRpZCI6IjIxNTYwOTQxMyIsInNlcnZpY2VzIjoiMTAzIiwiaXNzIjoiY29ubmVjdC50Y3Njb3VyaWVyLmNvbSIsImp0aSI6IjM3Njk1NDU2LTdhZjYtNDBkOC1iMzM5LTYxNjQ4N2ZlNmUxYiIsIm5iZiI6MTY4NTA5NTY5OCwiZXhwIjoxNzcxNDk5Mjk4LCJpYXQiOjE2ODUwOTU2OTh9.RBlI_RrNx6vdXjk_sLAMVlo2uuT8E5IXLt26RW5t1P0' // Replace with your actual token
      // //   }
      // // });

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      // const data = await response.json();

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/order/track/${consignmentNo}`);
      console.log(data);

      // setShipment(data.shipmentinfo[0]);
      setActiveStep(steps.findIndex(step => step.label === data.orderStatus));
      setTrackingInfo(data); // Store checkpoints in state
      setError('');
    } catch (err) {
      setTrackingInfo(null);
      setError('Parcel not found, make sure tracking number is correct.');
    }
  };

  const handleTrack = () => {
    fetchTrackingInfo(consignmentNo);
  };

  return (
    <Paper
      square
      elevation={10}
      sx={{ p: 3, display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      {' '}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '5vh' }}>
        <FormControl
          sx={{
            maxWidth: '50%',
            borderRadius: '10px',
            width: '100%',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 0 5px #ec1c24',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter Consignment Number"
            value={consignmentNo}
            onChange={e => setConsignmentNo(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      textTransform: 'none',
                      fontSize: '1rem',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      color: 'white',
                      backgroundColor: '#ec1c24',
                      boxShadow: '0 0 5px #ec1c24',
                    }}
                    onClick={handleTrack}
                  >
                    Track
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      {error && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '5vh' }}>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </Box>
      )}
      {trackingInfo && (
        <>
          <Typography variant="h1" sx={{ marginY: '2vh', textAlign: 'center' }}>
            {' '}
            Tracking Information{' '}
          </Typography>
          <Typography sx={{ textAlign: 'center' }}> Created {trackingInfo.createdAt}</Typography>
          <Typography sx={{ textAlign: 'center' }}> Weight {trackingInfo.totalWeight}kg</Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '5vh',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h4" sx={{ marginY: '2vh', textAlign: 'center' }}>
                {' '}
                Origin{' '}
              </Typography>
              <Typography variant="h6" sx={{ marginY: '0.5vh', textAlign: 'center' }}>
                {' '}
                {trackingInfo.origin}{' '}
              </Typography>
              <Typography variant="h6" sx={{ marginY: '0.5vh', textAlign: 'center' }}>
                {' '}
                {trackingInfo.originAddress.city}{' '}
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h4" sx={{ marginY: '2vh', textAlign: 'center' }}>
                {' '}
                Destination{' '}
              </Typography>
              <Typography variant="h6" sx={{ marginY: '0.5vh', textAlign: 'center' }}>
                {' '}
                {trackingInfo.destination}{' '}
              </Typography>
              <Typography variant="h6" sx={{ marginY: '0.5vh', textAlign: 'center' }}>
                {' '}
                {trackingInfo.destinationAddress.city}{' '}
              </Typography>
            </Box>
          </Box>

          {/* {shipment ? <ShipmentDetails shipment={shipment} /> : <p>Loading shipment details...</p>} */}
          {/* <Typography variant="h1" sx={{ mb: '2vh', textAlign: 'center' }}>
                        {' '}
                        Track History{' '}
                    </Typography> */}
          {/* <TrackingTable checkpoints={trackingInfo} /> */}
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={index === steps.length - 1 ? <Typography variant="caption">(Complete)</Typography> : null}
                  >
                    {step.label}
                  </StepLabel>
                  {/* <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </Box>
                  </StepContent> */}
                </Step>
              ))}
            </Stepper>
            {/* {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>Parcel delivered successfully 🎉 🎉</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )} */}
          </Box>
        </>
      )}
    </Paper>
  );
};

export default TrackingInput;
