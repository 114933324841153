import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Typography,
    IconButton,
    Modal,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputAdornment,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateItem, removeItem, setTotalCost, setPickupFee } from '../state';
import { WEIGHT_BASED_DM_VALIDATIONS, WEIGHTS } from '../scenes/orderpage/AddItem';

const DEFAULT_ERRORS = {
    width: '',
    height: '',
    length: '',
    weight: '',
    description: '',
};

const ItemsList = () => {
    const orderId = useSelector(state => state.auth.id);
    const items = useSelector(state => state.auth.items);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [errors, setErrors] = useState({
        ...DEFAULT_ERRORS,
    });

    const validateWidth = value => {
        return !value || isNaN(value) || value <= 0 || value > WEIGHT_BASED_DM_VALIDATIONS[editingItem.weight].width;
    };
    const validateHeight = value => {
        return !value || isNaN(value) || value <= 0 || value > WEIGHT_BASED_DM_VALIDATIONS[editingItem.weight].height;
    };
    const validateLength = value => {
        return !value || isNaN(value) || value <= 0 || value > WEIGHT_BASED_DM_VALIDATIONS[editingItem.weight].length;
    };

    const validateWeight = value => !value || isNaN(value) || value <= 0 || value > WEIGHTS.UP_TO_25;
    const validateDescription = value => !value.trim();

    // Handle the click on the edit button
    const handleEditClick = item => {
        setEditingItem(item);
        setIsModalOpen(true);
        setErrors({
            ...DEFAULT_ERRORS,
        });
    };

    const handleInputChange = (e, field) => {
        setEditingItem({ ...editingItem, [field]: e.target.value });
    };

    const handleSave = async () => {
        const newErrors = {
            width: validateWidth(editingItem.width)
                ? !editingItem.weight
                    ? 'Invalid width'
                    : `Must be ${WEIGHT_BASED_DM_VALIDATIONS[editingItem.weight].width} or less`
                : '',
            height: validateHeight(editingItem.height)
                ? !editingItem.weight
                    ? 'Invalid height'
                    : `Must be ${WEIGHT_BASED_DM_VALIDATIONS[editingItem.weight].height} or less`
                : '',
            length: validateLength(editingItem.length)
                ? !editingItem.weight
                    ? 'Invalid length'
                    : `Must be ${WEIGHT_BASED_DM_VALIDATIONS[editingItem.weight].length} or less`
                : '',
            weight: validateWeight(editingItem.weight) ? 'Invalid weight or exceeds 25kg limit' : '',
            description: validateDescription(editingItem.description) ? 'Enter description' : '',
        };

        setErrors(newErrors);

        if (newErrors.width || newErrors.height || newErrors.length || newErrors.weight || newErrors.description) {
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/order/editItem/${orderId}/${editingItem.id}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(editingItem),
                }
            );

            const data = await response.json();
            const newItem = data.order.items.find(item  => item._id === editingItem.id);
            newItem.id = newItem._id;
            delete newItem._id;

            if (response.ok) {
                dispatch(updateItem({ id: editingItem.id, item: newItem }));
                dispatch(setTotalCost({ totalCost: data.order.totalCost }));
                dispatch(setPickupFee({ pickupFee: data.order.pickupPrice }));
                console.log('Item EDITTED successfully:', data);
            } else {
                console.error('Failed to add item:', data);
            }
        } catch (error) {
            console.error('Error adding item:', error);
        }

        setIsModalOpen(false);
    };

    const handleRemove = async itemId => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/order/removeItem/${orderId}/${itemId}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            if (response.ok) {
                // If the backend deletion is successful, remove the item from Redux store
                dispatch(setTotalCost({ totalCost: data.order.totalCost }));
                dispatch(setPickupFee({ pickupFee: data.order.pickupPrice }));
                dispatch(removeItem(itemId));
                console.log(`Item with id ${itemId} removed successfully.`);
            } else {
                console.error('Failed to remove item.');
            }
        } catch (error) {
            console.error('Error removing item:', error);
        }
    };

    return (
        <>
            <Box sx={{ width: '100%', marginTop: '3vh' }}>
                {items && items.length > 0 && (
                    <Typography variant="h4" marginTop="2rem" sx={{ fontWeight: '700', color: '#2f3135' }} align="left">
                        Your Order
                    </Typography>
                )}
                <Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
                    {items.map((item, index) => (
                        <Box
                            component="li"
                            key={item.id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 2,
                                p: 2,
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                            }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1">
                                    {index + 1}. {item.type} - {item.weight}kg
                                </Typography>
                                <Typography variant="body2">Description: {item.description}</Typography>
                            </Box>
                            <Box>
                                <IconButton aria-label="edit" color="disabled" onClick={() => handleEditClick(item)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton aria-label="delete" color="disabled" onClick={() => handleRemove(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        width: '80%', // Sets the width to 80% of the viewport width
                        maxWidth: '700px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {editingItem && (
                        <>
                            <Typography variant="h4" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                Edit Item
                            </Typography>

                            <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1}>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems={'center'}
                                >
                                    <Typography variant="h5" sx={{ fontWeight: '400' }}>
                                        Weight
                                    </Typography>
                                    <img
                                        src={`/assets/weight.svg`}
                                        alt="Icon"
                                        style={{ height: '45px', width: isMobileOrTablet ? '30px' : '50px' }}
                                    />
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        id="weight"
                                        error={!!errors.weight}
                                        helperText={errors.weight}
                                        value={editingItem.weight}
                                        displayEmpty
                                        onChange={e => handleInputChange(e, 'weight')}
                                        sx={{
                                            transition: 'box-shadow 0.3s ease-in-out',
                                            '&:hover': {
                                                boxShadow: '0 0 5px #ec1c24',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select weight</em>
                                        </MenuItem>
                                        {[
                                            WEIGHTS.UP_TO_5,
                                            WEIGHTS.UP_TO_10,
                                            WEIGHTS.UP_TO_15,
                                            WEIGHTS.UP_TO_20,
                                            WEIGHTS.UP_TO_25,
                                        ].map(number => (
                                            <MenuItem key={number} value={number}>
                                                Up to {number} kg
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={'1rem'}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="left"
                                    gap={1}
                                    flex={1}
                                    marginRight={2}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems={'center'}
                                    >
                                        <Typography variant="h5" sx={{ fontWeight: '400' }}>
                                            Width
                                        </Typography>
                                        <img
                                            src={`/assets/width.svg`}
                                            alt="Icon"
                                            style={{
                                                minHeight: '45px',
                                                height: 'auto',
                                                width: isMobileOrTablet ? '30px' : '60px',
                                            }}
                                        />
                                    </Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            type="number" // Makes the input accept only numbers
                                            inputMode="numeric"
                                            id="width"
                                            error={!!errors.width}
                                            helperText={errors.width}
                                            value={editingItem.width}
                                            onChange={e => handleInputChange(e, 'width')}
                                            fullWidth
                                            placeholder="0"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{
                                                            backgroundColor: '#D3D3D3',
                                                            borderRadius: '4px',
                                                            paddingY: '26px',
                                                            paddingX: '12px',
                                                            marginRight: '-14px',
                                                        }}
                                                    >
                                                        cm
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    // Target the root of the input element for hover effect
                                                    transition: 'box-shadow 0.3s ease-in-out',
                                                    '&:hover': {
                                                        boxShadow: '0 0 5px #ec1c24',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                        },
                                                    },
                                                    '&.Mui-error:hover': {
                                                        boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                                    },
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="left"
                                    gap={1}
                                    flex={1}
                                    marginRight={2}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems={'center'}
                                    >
                                        <Typography variant="h5" sx={{ fontWeight: '400' }}>
                                            Height
                                        </Typography>
                                        <img
                                            src={`/assets/height.svg`}
                                            alt="Icon"
                                            style={{
                                                minHeight: '45px',
                                                height: 'auto',
                                                width: isMobileOrTablet ? '30px' : '60px',
                                            }}
                                        />
                                    </Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            type="number" // Makes the input accept only numbers
                                            inputMode="numeric"
                                            id="height"
                                            error={!!errors.height}
                                            helperText={errors.height}
                                            value={editingItem.height}
                                            onChange={e => handleInputChange(e, 'height')}
                                            fullWidth
                                            placeholder="0"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{
                                                            backgroundColor: '#D3D3D3',
                                                            borderRadius: '4px',
                                                            paddingY: '26px',
                                                            paddingX: '12px',
                                                            marginRight: '-14px',
                                                        }}
                                                    >
                                                        cm
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    // Target the root of the input element for hover effect
                                                    transition: 'box-shadow 0.3s ease-in-out',
                                                    '&:hover': {
                                                        boxShadow: '0 0 5px #ec1c24',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                        },
                                                    },
                                                    '&.Mui-error:hover': {
                                                        boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                                    },
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems={'center'}
                                    >
                                        <Typography variant="h5" sx={{ fontWeight: '400' }}>
                                            Length
                                        </Typography>
                                        <img
                                            src={`/assets/length.svg`}
                                            alt="Icon"
                                            style={{
                                                minHeight: '45px',
                                                height: 'auto',
                                                width: isMobileOrTablet ? '30px' : '60px',
                                            }}
                                        />
                                    </Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            type="number" // Makes the input accept only numbers
                                            inputMode="numeric"
                                            id="length"
                                            error={!!errors.length}
                                            helperText={errors.length}
                                            value={editingItem.length}
                                            onChange={e => handleInputChange(e, 'length')}
                                            fullWidth
                                            placeholder="0"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{
                                                            backgroundColor: '#D3D3D3',
                                                            borderRadius: '4px',
                                                            paddingY: '26px',
                                                            paddingX: '12px',
                                                            marginRight: '-14px',
                                                        }}
                                                    >
                                                        cm
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    // Target the root of the input element for hover effect
                                                    transition: 'box-shadow 0.3s ease-in-out',
                                                    '&:hover': {
                                                        boxShadow: '0 0 5px #ec1c24',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                        },
                                                    },
                                                    '&.Mui-error:hover': {
                                                        boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                                    },
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                gap={1}
                                flex={1}
                                marginBottom={'1rem'}
                            ></Box>
                            <Box display="flex" flexDirection="column" alignItems="left" gap={1} flex={1}>
                                <Typography variant="h5" sx={{ minWidth: '6rem', fontWeight: '400' }}>
                                    Box Description
                                </Typography>
                                <FormControl fullWidth>
                                    <TextField
                                        id="description"
                                        error={!!errors.description}
                                        helperText={errors.description}
                                        value={editingItem.description}
                                        onChange={e => handleInputChange(e, 'description')}
                                        fullWidth
                                        placeholder="e.g. Color, Make"
                                        variant="outlined"
                                        sx={{
                                            '.MuiOutlinedInput-root': {
                                                // Target the root of the input element for hover effect
                                                transition: 'box-shadow 0.3s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: '0 0 5px #ec1c24',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none', // This affects the static state; ensure it aligns with your design requirements
                                                    },
                                                },
                                                '&.Mui-error:hover': {
                                                    boxShadow: '0 0 5px red', // Optional: Differentiate the hover effect for error state
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Box>

                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: 'white',
                                        border: '1px solid #ec1c24',
                                        borderRadius: '10px',
                                        color: '#2f3135',
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Drop shadow for the button
                                        paddingX: '32px', // Horizontal padding
                                        '&:hover': {
                                            backgroundColor: 'white', // Maintain the background color on hover
                                            boxShadow: '0 0 5px #ec1c24', // Custom boxShadow on hover
                                        },
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default ItemsList;
